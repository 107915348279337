import React, { useState, useEffect } from "react";

// Icon

import ScrollArrow from "../../../components/UI/scrollArrow";

const Intro = () => {
  const [className, setClassName] = useState({
    partOneClass: "intro-part-one-disabled",
    partTwoClass: "intro-part-two-disabled",
  });

  // Retrieve data from store and setLocalState

  const getStoreSetState = () => {
    const partOneClass = window.sessionStorage.getItem("partOneClass");
    const partTwoClass = window.sessionStorage.getItem("partTwoClass");
    setClassName({
      partOneClass: partOneClass,
      partTwoClass: partTwoClass,
    });
  };

  useEffect(() => {
    const sessionStorage = window.sessionStorage;
    const sessionStoreItemOne = sessionStorage.getItem("partOneClass");
    const sessionStoreItemTwo = sessionStorage.getItem("partTwoClass");

    // Check if session storage is empty,
    // if so set it with the initial classNames

    if ((sessionStoreItemOne, sessionStoreItemTwo === null || undefined)) {
      sessionStorage.setItem("partOneClass", "intro-part-one");
      sessionStorage.setItem("partTwoClass", "intro-part-two-disabled");

      // Call function that gets the new data from the
      // session storage and setslocalState
      getStoreSetState();

      // Start a timer that evantually sets the session storage
      // with new classNames and calls a function that gets
      // the new data and setsLocalState. And lastly call a function
      // thats set the store with the last className.

      setTimeout(function () {
        sessionStorage.setItem("partOneClass", "intro-part-one-disabled");
        sessionStorage.setItem("partTwoClass", "intro-part-two");
        getStoreSetState();
        handleLastChange();
      }, 2350);
    }
    // Start a timer that evantually sets the store with the last className
    // and then calls a function that gets and setsLocalState.
    const handleLastChange = () => {
      setTimeout(function () {
        window.sessionStorage.setItem("partTwoClass", "intro-part-three");
        getStoreSetState();
      }, 3000);
    };
    getStoreSetState();
  }, [setClassName]);

  return (
    <section id="about-intro">
      <div className={className.partOneClass}>
        <div className="hi-box">
          <h2 className="hero-heading letter-one">H</h2>
          <h2 className="hero-heading letter-two">i</h2>
        </div>
        <div className="there-box">
          <h2 className="hero-heading letter-one">t</h2>
          <h2 className="hero-heading letter-two">h</h2>
          <h2 className="hero-heading letter-three">e</h2>
          <h2 className="hero-heading letter-four">r</h2>
          <h2 className="hero-heading letter-five">e</h2>
        </div>
      </div>

      <div className={className.partTwoClass}>
        <div className="word-one">
          <h2 className="hero-heading letter-one">I</h2>
          <h2 className="hero-heading letter-two">'</h2>
          <h2 className="hero-heading letter-three">m</h2>
        </div>
        <div className="word-two">
          <h2 className="hero-heading letter-one">G</h2>
          <h2 className="hero-heading letter-two">u</h2>
          <h2 className="hero-heading letter-three">m</h2>
          <h2 className="hero-heading letter-four">m</h2>
          <h2 className="hero-heading letter-five">i</h2>
        </div>
        <div className="word-three">
          <h2 className="hero-heading letter-one">a</h2>
          <h2 className="hero-heading letter-two">n</h2>
          <h2 className="hero-heading letter-three">d</h2>
        </div>
        <div className="word-four">
          <h2 className="hero-heading letter-one">I</h2>
        </div>
        <div className="word-five">
          <h2 className="hero-heading letter-one">d</h2>
          <h2 className="hero-heading letter-two">o</h2>
        </div>
        <div className="word-six">
          <h2 className="hero-heading letter-one">w</h2>
          <h2 className="hero-heading letter-two">e</h2>
          <h2 className="hero-heading letter-three">b</h2>
        </div>
      </div>
      <ScrollArrow />
    </section>
  );
};

export default Intro;
