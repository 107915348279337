import React from "react";
import { useInView } from "react-intersection-observer";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";

const ScrollArrow = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    // delay: 100,
  });
  return (
    <div className="icon-holder" ref={ref}>
      <FiChevronDown
        size={32}
        className={inView ? "scroll-arrow" : "scroll-arrow-hidden"}
      />
    </div>
  );
};

export default ScrollArrow;
